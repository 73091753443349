/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { } from 'react';
import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import Loader from 'Component/Loader';
import { RootState } from 'Util/Store/Store.type';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        ...SourceCmsBlockContainer.propTypes,
        onLoad: PropTypes.func,
        showLoader: PropTypes.bool,
        textPlaceholderLength: TextPlaceHolderLength.MEDIUM,
        placeHolderSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        }),
        placeHolderMobileSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        })
    };

    static defaultProps = {
        onLoad: (_identifier) => { },
        showLoader: false,
        placeHolderSize: {
            width: '',
            height: ''
        },
        placeHolderMobileSize: {
            width: '',
            height: ''
        }
    };

    containerProps() {
        const { blockType, children, placeHolderMobileSize, placeHolderSize, showLoader, device, textPlaceholderLength } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, children, placeHolderSize, placeHolderMobileSize, showLoader, device, textPlaceholderLength };
    }


    componentDidUpdate(oldProps) {
        const { identifier: old_identifier } = oldProps;
        const { identifier: new_identifier, onLoad } = this.props;
        if (old_identifier !== new_identifier) {
            this._getCmsBlock();
            onLoad(new_identifier);
        }
        setTimeout(() => {
            this.loadSwiperSlider();
        }, 1000);
    }

    loadSwiperSlider(){

        new Swiper('.hm-brands-items', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: true,
            navigation: {
                nextEl: ".hm-brands-next",
                prevEl: ".hm-brands-prev",
            },
            modules: [Navigation],
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
            },
        })

        new Swiper('.hm-trend-cat-items', {
            slidesPerView: 6,
            spaceBetween: 30,
            loop: true,
            navigation: {
                nextEl: ".hm-trend-cat-next",
                prevEl: ".hm-trend-cat-prev",
            },
            modules: [Navigation],
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1366: {
                  slidesPerView: 6,
                  spaceBetween: 30,
                },
            },
        })
        
    }

    render() {
        const { showLoader, placeHolderSize, placeHolderMobileSize, device } = this.props;
        const { cmsBlock } = this.state;

        if (!cmsBlock.identifier) {
            if (placeHolderSize.width || placeHolderSize.height) {
                return <div block="ContentWrapper"><div style={placeHolderSize}  block="CmsBlock" elem="Loader" /></div>;
            }

            if (showLoader) {
                return <Loader isLoading />;
            }
        }
        if (device.isMobile) {
            if (!cmsBlock.identifier) {
                if (placeHolderMobileSize.width || placeHolderMobileSize.height) {
                    return <div block="ContentWrapper"><div style={placeHolderMobileSize}  block="CmsBlock" elem="Loader" mix={{block:'MobileLoader'}} /></div>;
                }

                if (showLoader) {
                    return <Loader isLoading />;
                }
            }
        }

        return (
            <CmsBlock
                {...this.containerProps()}
            />
        );
    }
}

// export default CmsBlockContainer;
export default connect(mapStateToProps)(CmsBlockContainer);
